// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-post-js": () => import("D:\\Web-Projects\\adriandinca.com\\src\\templates\\project-post.js" /* webpackChunkName: "component---src-templates-project-post-js" */),
  "component---src-pages-404-js": () => import("D:\\Web-Projects\\adriandinca.com\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("D:\\Web-Projects\\adriandinca.com\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-js": () => import("D:\\Web-Projects\\adriandinca.com\\src\\pages\\resume.js" /* webpackChunkName: "component---src-pages-resume-js" */)
}

